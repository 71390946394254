import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createStateSyncMiddleware, initMessageListener  } from "redux-state-sync";
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import * as Login from './Login';
import * as NavMenu from './NavMenu';
import * as Users from './Users';
import * as UserEdit from './UserEdit';
import * as Settings from './Settings';
import * as Dictionary from './Dictionary';
import * as DictionaryEdit from './DictionaryEdit';
import * as DictionaryList from './DictionaryList';
import * as Roles from './Roles';
import * as RoleEdit from './RoleEdit';
import * as ImportSuppliers from './ImportSuppliers';
import * as SupplierEdit from './SupplierEdit';
import * as LoginLogs from './LoginLogs';
import * as MailLogs from './MailLogs';
import * as ExportLogs from './ExportLogs';
import * as ChangeLogs from './ChangeLogs';
import * as ReportDebts from './ReportDebts';
import * as ReportResults from './ReportResults';
import * as GpData from './GpData';
import * as GpDataForms from './GpDataForms';
import * as GpDataFormView from './GpDataFormView';
import * as GpInforming from './GpInforming';
import * as TermEdit from './TermEdit';
import * as Forms from './Forms';
import * as InputRules from './InputRules';
import * as TaskSettings from './TaskSettings';
import * as TaskSettingsEdit from './TaskSettingsEdit';
import * as Notifications from './Notifications';
import * as NotificationEdit from './NotificationEdit';
import * as Mailings from './Mailings';
import * as MailingEdit from './MailingEdit';
import * as MyTasks from './MyTasks';
import * as RuleEdit from './RuleEdit';

export default function configureStore (history, initialState) {
    const reducers = {
        login: Login.reducer,
        navMenu: NavMenu.reducer,
        users: Users.reducer,
        userEdit: UserEdit.reducer,
        roles: Roles.reducer,
        roleEdit: RoleEdit.reducer,
        settings: Settings.reducer,
        dictionaryEdit: DictionaryEdit.reducer,
        dictionaryList: DictionaryList.reducer,
        dictionary: Dictionary.reducer,
        importSuppliers: ImportSuppliers.reducer,
        supplierEdit: SupplierEdit.reducer,
        loginLogs: LoginLogs.reducer,
        mailLogs: MailLogs.reducer,
        exportLogs: ExportLogs.reducer,
        changeLogs: ChangeLogs.reducer,
        reportResults: ReportResults.reducer,
        reportDebts: ReportDebts.reducer,
        gpData: GpData.reducer,
        gpDataForms: GpDataForms.reducer,
        gpDataFormView: GpDataFormView.reducer,
        gpInforming: GpInforming.reducer,
        termEdit: TermEdit.reducer,
        forms: Forms.reducer,
        inputRules: InputRules.reducer,
        taskSettings: TaskSettings.reducer,
        taskSettingsEdit: TaskSettingsEdit.reducer,
        myTasks: MyTasks.reducer,
        notificationEdit: NotificationEdit.reducer,
        notifications: Notifications.reducer,
        mailingEdit: MailingEdit.reducer,
        mailings: Mailings.reducer,
        ruleEdit: RuleEdit.reducer
    };

    const reduxStateSyncConfig = {
        whitelist: ["CHANGE_GP_INFO"]
    };

    const middleware = [
        thunk,
        routerMiddleware(history),
        createStateSyncMiddleware(reduxStateSyncConfig)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
    ...reducers,
        router: connectRouter(history)
    });

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    initMessageListener (store);

    return store;
}

