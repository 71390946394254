let HttpUtil = {
    fetchGetAsync: async (url, params) => {
        if (params) {
            url += "?";
            var paramsBody = Object.keys(params)
                .reduce((a, k) => {
                    a.push(k + "=" + encodeURIComponent(params[k].toString()));
                    return a;
                }, [])
                .join('&');
            url += paramsBody;
        }

        var options = {
            method: 'GET'
        };

        var token = localStorage.getItem("accessToken");
        options.headers = {
            'Authorization': `Bearer ${token}`
        }

        var response = await fetch(url, options);
        return await response.json();
    },

    fetchAsync: async (url, params, method) => {
        var token = localStorage.getItem("accessToken");
        var options = {
            method: method,
            body: `${JSON.stringify(params)}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        var response = await fetch(url, options);
        return await response.json();
    },

    fetchPostAsync: async (url, params) => {
        var options = {
            method: 'POST'
        };

        if (params) {
            if (typeof params !== 'string')
                url += "?";// "?key=" + KEY;

            if (typeof params !== 'string') {
                var paramsBodyArr = Object.keys(params)
                    .reduce((a, k) => {
                        if (k !== 'picture' && k !== 'text' && params[k] != null) {
                            a.push(k + "=" + encodeURIComponent(params[k].toString()));
                        }
                        return a;
                    }, []);
                var paramsBody = paramsBodyArr.join('&');
                url += /*"&" + */paramsBody;
            } else {
                options.body = `'${params}'`
            }
        }

        var token = localStorage.getItem("accessToken");
        options.headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }

        var response = await fetch(url, options);
        return await response.json();
    },
    fetchPostFileAsync: async (url, params) => {
        let data = new FormData();

        data.append('bytes', params.file);
        for (var key in params) {
            if (key !== 'file') {
                data.append(key, params[key])
            }
        };

        var token = localStorage.getItem("accessToken");
        const options = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: data,
        }

        var response = await fetch(url, options);
        return await response.json();
    },

    fetchFileAsync: async (url, successCallback, failCallback) => {
        var options = {
            method: 'GET'
        };

        options.headers = {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }

        var response = await fetch(url, options);
        var responseObj = response.blob();
        console.log(responseObj);
        if (responseObj.error && failCallback) {
            await failCallback(responseObj.error);
        }
        else if (responseObj !== undefined) {
            await successCallback(responseObj);
        }
    },

}

export default HttpUtil;
