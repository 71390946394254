import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType } from './NavMenu';

const changeCurrentFiltersType = "CHANGE_CURRENT_USERS_FILTERS";
const changeCurrentSearchType = "CHANGE_CURRENT_USERS_SEARCH";
const changeCurrentSortingType = "CHANGE_CURRENT_USERS_SORTING";

const initialState = {
    filters: [{ column: "status", value : ["Активен"] }],
    search: "",
    sorting: [{ column: "userName", dir: "asc" }]
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    showMessage: (message) => ({ type: showSnackBarType, message }),
    changeFilters: (filters) => (dispatch, getState) => {
        dispatch({ type: changeCurrentFiltersType, filters });
    },
    changeSearch: (search) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSearchType, search });
    },
    changeSorting: (sorting) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSortingType, sorting });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === changeCurrentFiltersType) {
        return {
            ...state,
            filters: action.filters
        };
    }

    if (action.type === changeCurrentSortingType) {
        return {
            ...state,
            sorting: action.sorting
        };
    }

    if (action.type === changeCurrentSearchType) {
        return {
            ...state,
            search: action.search
        };
    }

    return state;
};
