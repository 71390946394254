export function GetRole() {
    var token = localStorage.getItem("accessToken");
    if (!token || token === "null" || token === "undefined") {
        return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var tokenStr = window.atob(base64);
    var role = JSON.parse(tokenStr).role;
    return role;
}

export function CheckClaim(name) {
    var token = localStorage.getItem("accessToken");
    if (!token || token === "null" || token === "undefined") {
        return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var tokenStr = window.atob(base64);
    var claimValue = JSON.parse(tokenStr)[name];
    return claimValue === "1";
}

export function GetUserId() {
    try {
        var token = localStorage.getItem("accessToken");
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var tokenStr = window.atob(base64);
        var id = JSON.parse(tokenStr).sub;
        return id;
    } catch {
        return "";
    }
}

export function IsFirefox() {
    return navigator.userAgent.includes("Firefox");
}

export function IsTokenExpired() {
    try {
        var token = localStorage.getItem("accessToken");
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var tokenStr = window.atob(base64);
        var exp = JSON.parse(tokenStr).exp;
        var timeRemain = exp * 1000 - Date.now();
        //return timeRemain < 5 * 60 * 1000; //5 минут
        //на 8 минуте возвращался true и приложение думало, что уже все придется перелогиниваться, хотя токен ещё нормальный
        return timeRemain < 0; //5 минут
    } catch {
        return true;
    }
}

export function GetTokenExpired() {
    try {
        var token = localStorage.getItem("accessToken");
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var tokenStr = window.atob(base64);
        var exp = JSON.parse(tokenStr).exp;
        var timeRemain = exp * 1000 - Date.now();
        return timeRemain;
    } catch {
        return 0;
    }
}

export function ValidateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const DATE_FORMAT = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
};

const DATE_TIME_FORMAT = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
};

export function ToRuDate(dateStr, needTime) {
    if (dateStr === "" || dateStr === null || dateStr === undefined) {
        return "";
    }

    if (typeof dateStr === "number") {
        return needTime ? new Date(dateStr).toLocaleDateString("ru-RU", DATE_TIME_FORMAT) : new Date(dateStr).toLocaleDateString("ru-RU", DATE_FORMAT);
    }

    var strValue = dateStr.replace("Z", "");
    var date;
    if (new Date(strValue).getTime() === new Date(strValue + "Z").getTime()) {
        var offset = - new Date().getTimezoneOffset() * 60 * 1000;
        date = new Date(new Date(strValue) - offset);
    } else {
        date = new Date(strValue);
    }
    return needTime ? date.toLocaleDateString("ru-RU", DATE_TIME_FORMAT) : date.toLocaleDateString("ru-RU", DATE_FORMAT);
}

export function rus_to_latin(str, needSpecial) {
    var ru = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
    }, n_str = [];

    str = str.replace(/[ъьЪЬ]+/g, '').replace(/[йЙ]/g, 'i');

    for (var i = 0; i < str.length; ++i) {
        n_str.push(
            ru[str[i]]
            || (ru[str[i].toLowerCase()] === undefined && str[i])
            || ru[str[i].toLowerCase()].replace(/^(.)/, function (match) { return match.toUpperCase() })
        );
    }

    let latinStr = n_str.join('');
    if (needSpecial) {
        latinStr = latinStr.replace(/[\W]+/g, '_');
    }

    return latinStr;
}

export function FirstUpper(s) {
    return s[0].toUpperCase() + s.substr(1).toLowerCase();
}

export function CreateFile(reqFile, type) {
    var base64str = reqFile;

    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    //var file = new File([view], reqFile.name, { type: 'application/pdf', lastModified: new Date(reqFile.date) });
    var file;
    if (type)
        file = new Blob([view], { type: type });
    else
        file = new Blob([view], { type: 'application/pdf' });
    file.name = reqFile.name;
    file.lastModified = new Date(reqFile.date);
    var url = URL.createObjectURL(file);

    file.preview = url;
    return file;
}

export function BrowserCheck(userAgent) {
    var _ua = userAgent;
    const commonVersionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;

    function getFirstMatch(regexp, ua) {
        const match = ua.match(regexp);
        return (match && match.length > 0 && match[1]) || '';
    }

    function getSecondMatch(regexp, ua) {
        const match = ua.match(regexp);
        return (match && match.length > 1 && match[2]) || '';
    }


    function find(arr, predicate) {
        let i;
        let l;
        if (Array.prototype.find) {
            return Array.prototype.find.call(arr, predicate);
        }
        for (i = 0, l = arr.length; i < l; i += 1) {
            const value = arr[i];
            if (predicate(value, i)) {
                return value;
            }
        }
        return undefined;
    }

    const browserParsersList = [
        /* Googlebot */
        {
            test: [/googlebot/i],
            describe(ua) {
                const browser = {
                    name: 'Googlebot',
                };
                const version = getFirstMatch(/googlebot\/(\d+(\.\d+))/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },

        /* Opera < 13.0 */
        {
            test: [/opera/i],
            describe(ua) {
                const browser = {
                    name: 'Opera',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:opera)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },

        /* Opera > 13.0 */
        {
            test: [/opr\/|opios/i],
            describe(ua) {
                const browser = {
                    name: 'Opera',
                };
                const version = getFirstMatch(/(?:opr|opios)[\s/](\S+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/SamsungBrowser/i],
            describe(ua) {
                const browser = {
                    name: 'Samsung Internet for Android',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:SamsungBrowser)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/Whale/i],
            describe(ua) {
                const browser = {
                    name: 'NAVER Whale Browser',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:whale)[\s/](\d+(?:\.\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/MZBrowser/i],
            describe(ua) {
                const browser = {
                    name: 'MZ Browser',
                };
                const version = getFirstMatch(/(?:MZBrowser)[\s/](\d+(?:\.\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/focus/i],
            describe(ua) {
                const browser = {
                    name: 'Focus',
                };
                const version = getFirstMatch(/(?:focus)[\s/](\d+(?:\.\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/swing/i],
            describe(ua) {
                const browser = {
                    name: 'Swing',
                };
                const version = getFirstMatch(/(?:swing)[\s/](\d+(?:\.\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/coast/i],
            describe(ua) {
                const browser = {
                    name: 'Opera Coast',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:coast)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/opt\/\d+(?:.?_?\d+)+/i],
            describe(ua) {
                const browser = {
                    name: 'Opera Touch',
                };
                const version = getFirstMatch(/(?:opt)[\s/](\d+(\.?_?\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/yabrowser/i],
            describe(ua) {
                const browser = {
                    name: 'Yandex Browser',
                };
                const version = getFirstMatch(/(?:yabrowser)[\s/](\d+(\.?_?\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/ucbrowser/i],
            describe(ua) {
                const browser = {
                    name: 'UC Browser',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:ucbrowser)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/Maxthon|mxios/i],
            describe(ua) {
                const browser = {
                    name: 'Maxthon',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:Maxthon|mxios)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/epiphany/i],
            describe(ua) {
                const browser = {
                    name: 'Epiphany',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:epiphany)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/puffin/i],
            describe(ua) {
                const browser = {
                    name: 'Puffin',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:puffin)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/sleipnir/i],
            describe(ua) {
                const browser = {
                    name: 'Sleipnir',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:sleipnir)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/k-meleon/i],
            describe(ua) {
                const browser = {
                    name: 'K-Meleon',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/(?:k-meleon)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/micromessenger/i],
            describe(ua) {
                const browser = {
                    name: 'WeChat',
                };
                const version = getFirstMatch(/(?:micromessenger)[\s/](\d+(\.?_?\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/qqbrowser/i],
            describe(ua) {
                const browser = {
                    name: (/qqbrowserlite/i).test(ua) ? 'QQ Browser Lite' : 'QQ Browser',
                };
                const version = getFirstMatch(/(?:qqbrowserlite|qqbrowser)[/](\d+(\.?_?\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/msie|trident/i],
            describe(ua) {
                const browser = {
                    name: 'Internet Explorer',
                };
                const version = getFirstMatch(/(?:msie |rv:)(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/\sedg\//i],
            describe(ua) {
                const browser = {
                    name: 'Microsoft Edge',
                };

                const version = getFirstMatch(/\sedg\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/edg([ea]|ios)/i],
            describe(ua) {
                const browser = {
                    name: 'Microsoft Edge',
                };

                const version = getSecondMatch(/edg([ea]|ios)\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/vivaldi/i],
            describe(ua) {
                const browser = {
                    name: 'Vivaldi',
                };
                const version = getFirstMatch(/vivaldi\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/seamonkey/i],
            describe(ua) {
                const browser = {
                    name: 'SeaMonkey',
                };
                const version = getFirstMatch(/seamonkey\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/sailfish/i],
            describe(ua) {
                const browser = {
                    name: 'Sailfish',
                };

                const version = getFirstMatch(/sailfish\s?browser\/(\d+(\.\d+)?)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/silk/i],
            describe(ua) {
                const browser = {
                    name: 'Amazon Silk',
                };
                const version = getFirstMatch(/silk\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/phantom/i],
            describe(ua) {
                const browser = {
                    name: 'PhantomJS',
                };
                const version = getFirstMatch(/phantomjs\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/slimerjs/i],
            describe(ua) {
                const browser = {
                    name: 'SlimerJS',
                };
                const version = getFirstMatch(/slimerjs\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/blackberry|\bbb\d+/i, /rim\stablet/i],
            describe(ua) {
                const browser = {
                    name: 'BlackBerry',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/blackberry[\d]+\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/(web|hpw)[o0]s/i],
            describe(ua) {
                const browser = {
                    name: 'WebOS Browser',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua) || getFirstMatch(/w(?:eb)?[o0]sbrowser\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/bada/i],
            describe(ua) {
                const browser = {
                    name: 'Bada',
                };
                const version = getFirstMatch(/dolfin\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/tizen/i],
            describe(ua) {
                const browser = {
                    name: 'Tizen',
                };
                const version = getFirstMatch(/(?:tizen\s?)?browser\/(\d+(\.?_?\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/qupzilla/i],
            describe(ua) {
                const browser = {
                    name: 'QupZilla',
                };
                const version = getFirstMatch(/(?:qupzilla)[\s/](\d+(\.?_?\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/firefox|iceweasel|fxios/i],
            describe(ua) {
                const browser = {
                    name: 'Firefox',
                };
                const version = getFirstMatch(/(?:firefox|iceweasel|fxios)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/electron/i],
            describe(ua) {
                const browser = {
                    name: 'Electron',
                };
                const version = getFirstMatch(/(?:electron)\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/MiuiBrowser/i],
            describe(ua) {
                const browser = {
                    name: 'Miui',
                };
                const version = getFirstMatch(/(?:MiuiBrowser)[\s/](\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/chromium/i],
            describe(ua) {
                const browser = {
                    name: 'Chromium',
                };
                const version = getFirstMatch(/(?:chromium)[\s/](\d+(\.?_?\d+)+)/i, ua) || getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/chrome|crios|crmo/i],
            describe(ua) {
                const browser = {
                    name: 'Chrome',
                };
                const version = getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },
        {
            test: [/GSA/i],
            describe(ua) {
                const browser = {
                    name: 'Google Search',
                };
                const version = getFirstMatch(/(?:GSA)\/(\d+(\.?_?\d+)+)/i, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },

        /* Android Browser */
        {
            test(parser) {
                const notLikeAndroid = !parser.test(/like android/i);
                const butAndroid = parser.test(/android/i);
                return notLikeAndroid && butAndroid;
            },
            describe(ua) {
                const browser = {
                    name: 'Android Browser',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },

        /* PlayStation 4 */
        {
            test: [/playstation 4/i],
            describe(ua) {
                const browser = {
                    name: 'PlayStation 4',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },

        /* Safari */
        {
            test: [/safari|applewebkit/i],
            describe(ua) {
                const browser = {
                    name: 'Safari',
                };
                const version = getFirstMatch(commonVersionIdentifier, ua);

                if (version) {
                    browser.version = version;
                }

                return browser;
            },
        },

        /* Something else */
        {
            test: [/.*/i],
            describe(ua) {
                /* Here we try to make sure that there are explicit details about the device
                 * in order to decide what regexp exactly we want to apply
                 * (as there is a specific decision based on that conclusion)
                 */
                const regexpWithoutDeviceSpec = /^(.*)\/(.*) /;
                const regexpWithDeviceSpec = /^(.*)\/(.*)[ \t]\((.*)/;
                const hasDeviceSpec = ua.search('\\(') !== -1;
                const regexp = hasDeviceSpec ? regexpWithDeviceSpec : regexpWithoutDeviceSpec;
                return {
                    name: getFirstMatch(regexp, ua),
                    version: getSecondMatch(regexp, ua),
                };
            },
        },
    ]

    var browser = {};
    const browserDescriptor = find(browserParsersList, (_browser) => {
        if (typeof _browser.test === 'function') {
            return _browser.test();
        }

        if (_browser.test instanceof Array) {
            return _browser.test.some(condition => condition.test(_ua));
        }

        throw new Error("Browser's test function is not valid");
    });

    if (browserDescriptor) {
        browser = browserDescriptor.describe(_ua);
    }

    return browser;
}
