import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType } from './NavMenu';

const initialState = {
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    showMessage: (message) => ({ type: showSnackBarType, message }),
};

export const reducer = (state, action) => {
    state = state || initialState;
    return state;
};
