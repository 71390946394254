import HttpUtil from "../util/HttpUtil";
import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType } from './NavMenu';

const fetchRulesFiltersType = "FETCH_RULES_FILTERS";
const changeCurrentFiltersType = "CHANGE_CURRENT_RULES_FILTERS";
const changeCurrentSearchType = "CHANGE_CURRENT_RULES_SEARCH";
const changeCurrentSortingType = "CHANGE_CURRENT_RULES_SORTING";

const initialState = {
    filters: [],
    curFilters: [{ column: "enabled", value: ["Да"] }],
    search: "",
    sorting: [{ column: "dependentField", dir: "asc"  }]
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    fetchFilters: () => async (dispatch, getState) => {
        var filtersData = await HttpUtil.fetchGet("api/Rules/GetFilters");
        dispatch({ type: fetchRulesFiltersType, filters: filtersData.filters });
    },
    showMessage: (message) => ({ type: showSnackBarType, message }),
    changeFilters: (filters) => (dispatch, getState) => {
        dispatch({ type: changeCurrentFiltersType, filters });
    },
    changeSearch: (search) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSearchType, search });
    },
    changeSorting: (sorting) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSortingType, sorting });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === fetchRulesFiltersType) {
        return {
            ...state,
            filters: action.filters
        };
    }

    if (action.type === changeCurrentFiltersType) {
        return {
            ...state,
            curFilters: action.filters
        };
    }

    if (action.type === changeCurrentSortingType) {
        return {
            ...state,
            sorting: action.sorting
        };
    }

    if (action.type === changeCurrentSearchType) {
        return {
            ...state,
            search: action.search
        };
    }

    return state;
};