import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType, showGlobalSaveType, hideGlobalSaveType, changeTasksCountType } from './NavMenu';

const changeCurrentFiltersType = "CHANGE_CURRENT_TASKS_FILTERS";
const changeCurrentSearchType = "CHANGE_CURRENT_TASKS_SEARCH";
const changeCurrentSortingType = "CHANGE_CURRENT_TASKS_SORTING";
const changeCurrentFiltersCtrlType = "CHANGE_CURRENT_TASKS_CONTROL_FILTERS";
const changeCurrentSearchCtrlType = "CHANGE_CURRENT_TASKS_CONTROL_SEARCH";
const changeCurrentSortingCtrlType = "CHANGE_CURRENT_TASKS_CONTROL_SORTING";

const initialState = {
    filters: [{ column: "status", value : ["Выполняется"] }],
    filtersCtrl: [{ column: "status", value : ["Выполняется"] }],
    search: "",
    searchCtrl: "",
    sorting: [{ column: "executeBefore", dir: "asc" }],
    sortingCtrl: [{ column: "executeBefore", dir: "asc" }]
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    showMessage: (message) => ({ type: showSnackBarType, message }),
    showSaving: () => ({ type: showGlobalSaveType }),
    stopSaving: () => ({ type: hideGlobalSaveType }),
    changeFilters: (filters) => (dispatch, getState) => {
        dispatch({ type: changeCurrentFiltersType, filters });
    },
    changeSearch: (search) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSearchType, search });
    },
    changeSorting: (sorting) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSortingType, sorting });
    },
    changeFiltersCtrl: (filters) => (dispatch, getState) => {
        dispatch({ type: changeCurrentFiltersCtrlType, filters });
    },
    changeSearchCtrl: (search) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSearchCtrlType, search });
    },
    changeSortingCtrl: (sorting) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSortingCtrlType, sorting });
    },
    reduceBadge: (count) => (dispatch, getState) => {
        dispatch({ type: changeTasksCountType, count: count });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === changeCurrentFiltersType) {
        return {
            ...state,
            filters: action.filters
        };
    }

    if (action.type === changeCurrentSortingType) {
        return {
            ...state,
            sorting: action.sorting
        };
    }

    if (action.type === changeCurrentSearchType) {
        return {
            ...state,
            search: action.search
        };
    }

    if (action.type === changeCurrentFiltersCtrlType) {
        return {
            ...state,
            filtersCtrl: action.filters
        };
    }

    if (action.type === changeCurrentSortingCtrlType) {
        return {
            ...state,
            sortingCtrl: action.sorting
        };
    }

    if (action.type === changeCurrentSearchCtrlType) {
        return {
            ...state,
            searchCtrl: action.search
        };
    }

    return state;
};
