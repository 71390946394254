import { showGlobalLoaderType, hideGlobalLoaderType } from './NavMenu';
import HttpUtil from '../util/HttpUtil';
const fetchDictinaryListType = "FETCH_DICTIONARY_LIST";

const initialState = {
    rows: null
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    fetch: () => async (dispatch, getState) => {
        dispatch({ type: showGlobalLoaderType });
        var data = await HttpUtil.fetchGetAsync("api/Dictionaries/List");
        setTimeout(() => dispatch({ type: hideGlobalLoaderType }), 400);
        dispatch({ type: fetchDictinaryListType, data: data.tables });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === fetchDictinaryListType) {
        return {
            ...state,
            rows: action.data
        }
    }

    return state;
};

