import HttpUtil from "../util/HttpUtil";
import { GetUserId, CheckClaim } from "../const/common";

export const hideDrawerType = "HIDE_DRAWER";
const openDrawerType = "OPEN_DRAWER";

const applyMenuVisibilityType = "APPLY_MENU_VISIBILITY";

export const hidePreScreenType = "HIDE_PRE_SCREEN";
export const logoutType = "LOGOUT";

export const checkLoginType = "CHECK_LOGIN";
export const showGlobalLoaderType = "SHOW_GLOBAL_LOADER";
export const showSnackBarType = "SHOW_SNACK_BAR";
const hideSnackBarType = "HIDE_SNACK_BAR";
export const hideGlobalLoaderType = "HIDE_GLOBAL_LOADER";
export const fetchNotificationsType = "FETCH_NOTIFICATIONS";
export const showGlobalSaveType = "SHOW_GLOBAL_SAVE";
export const hideGlobalSaveType = "HIDE_GLOBAL_SAVE";
export const changeMailStatusType = "CHANGE_MAIL_STATUS";
export const changeTasksCountType = "CHANGE_TASKS_COUNT";


export const initialState = {
    showLoginButton: true,
    drawerOpen: true,//localStorage.getItem("drawerOpen") === "false" ? false : true,
    searchComponent: null,
    user: null,
    globalLoading: false,
    globalSaving: false,
    showPreScreen: true,
    snackBarType: "default",
    snackBarMessage: "",
    mailData: null,
    taskCount: 0,
    menuVisibility: {},
    gpInfoPreview: ""
};

export const actionCreators = {
    showGlobalLoader: () => ({ type: showGlobalLoaderType }),
    hideGlobalLoader: () => ({ type: hideGlobalLoaderType }),
    showGlobalSave: () => ({ type: showGlobalSaveType }),
    hideGlobalSave: () => ({ type: hideGlobalSaveType }),
    hideSnackBar: () => ({ type: hideSnackBarType }),
    checkLogin: (callback) => async (dispatch, getState) => {
        var token = localStorage.getItem("accessToken");
        if (token) {
            try {
                var data = await HttpUtil.fetchGetAsync("api/Login/CheckToken", { refreshToken: localStorage.getItem("refreshToken") });
                if (data.accessToken !== undefined) {
                    localStorage.setItem("accessToken", data.accessToken);
                    localStorage.setItem("refreshToken", data.refreshToken);
                }

                dispatch({
                    type: checkLoginType,
                    isLogin: data.errorCode === 200 || data.error,
                    user: data.profile
                });
                dispatch({ type: hidePreScreenType });
            } catch {
                dispatch({ type: checkLoginType, isLogin: false });
                dispatch({ type: hidePreScreenType });
            }
        } else if (typeof document !== "undefined") {
            dispatch({ type: checkLoginType, isLogin: false });
        }
        callback && callback();
    },
    logout: (callback) => async (dispatch, getState) => {
        var userId = GetUserId();
        if (userId) {
            await HttpUtil.fetchGetAsync("api/Login/SignOut", { id: userId });
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
        }
        dispatch({ type: logoutType, isLogin: false });
        callback && callback();
    },
    hideDrawer: () => ({ type: hideDrawerType }),
    openDrawer: () => ({ type: openDrawerType }),
    changeMailData: (mailData) => ({ type: changeMailStatusType, mailData }),
    changeTasksCount: (count) => ({ type: changeTasksCountType, count }),
    setMenuVisibility: () => (dispatch, getState) => {
        let roles = CheckClaim("role_r");
        let users = CheckClaim("user_r");
        let settings = CheckClaim("settings_r");
        let task_settings = CheckClaim("tasksettings_r");
        let notifs =
            CheckClaim("mailenable_r") ||
            CheckClaim("mailterms_r") ||
            CheckClaim("mailsubject_r") ||
            CheckClaim("mailtext_r");

        let loginLog = CheckClaim("login_r");
        let mailLog = CheckClaim("mail_r");
        let exportLog = CheckClaim("export_r");
        let changeLog = CheckClaim("change_r");
        let logs = loginLog || mailLog || exportLog || changeLog;

        let mailings = CheckClaim("notif_r");

        let form1 = CheckClaim("form1_r");
        let form2 = CheckClaim("form2_r");
        let form3 = CheckClaim("form3_r");
        let form4 = CheckClaim("form4_r");

        let report_1 = CheckClaim("report1_r");
        let report_2 = CheckClaim("report2_r");

        dispatch({
            type: applyMenuVisibilityType,
            menuVisibility: {
                roles,
                users,
                settings,
                task_settings,
                notifications: notifs,
                admin: roles || users || settings || task_settings || notifs,

                logs_login: loginLog,
                logs_change: changeLog,
                logs_export: exportLog,
                logs_mail: mailLog,
                logs,

                dictionaries: CheckClaim("dict_r"),

                mailings,

                rules: CheckClaim("rule_r"),
                gp_data: CheckClaim("gp_r"),
                gp_informing: CheckClaim("gp_i"),
                ext_main_preview: CheckClaim("gp_i"),
                providing_information: form1,
                general_information: form2,
                provision_notifications: form3,
                provision_contracts: form4,
                analys: form1 || form2 || form3 || form4,

                report_1,
                report_2,
                reports: report_1 || report_2,

                tasks: true,

                browser_check: true
            }
        });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === applyMenuVisibilityType) {
        return {
            ...state,
            menuVisibility: action.menuVisibility
        }
    }

    if (action.type === changeMailStatusType) {
        return {
            ...state,
            mailData: action.mailData
        }
    }

    if (action.type === changeTasksCountType) {
        return {
            ...state,
            taskCount: action.count
        }
    }

    if (action.type === showGlobalLoaderType) {
        return {
            ...state,
            globalLoading: true
        }
    }

    if (action.type === hideGlobalLoaderType) {
        return {
            ...state,
            globalLoading: false
        }
    }

    if (action.type === showGlobalSaveType) {
        return {
            ...state,
            globalSaving: true
        }
    }

    if (action.type === hideGlobalSaveType) {
        return {
            ...state,
            globalSaving: false
        }
    }

    if (action.type === hidePreScreenType) {
        return {
            ...state,
            showPreScreen: false,
            globalLoading: false
        }
    }

    if (action.type === checkLoginType) {
        return {
            ...state,
            showLoginButton: action.isLogin,
            user: action.user
        }
    }

    if (action.type === logoutType) {
        return {
            ...state,
            drawerOpen: false,
            showLoginButton: false,
            user: null,
            menuVisibility: {}
        }
    }

    if (action.type === openDrawerType) {
        localStorage.setItem("drawerOpen", "true");
        return {
            ...state,
            drawerOpen: true
        }
    }

    if (action.type === hideDrawerType) {
        localStorage.setItem("drawerOpen", "false");
        return {
            ...state,
            drawerOpen: false
        }
    }

    if (action.type === showSnackBarType) {
        return {
            ...state,
            snackBarMessage: action.message,
            snackBarType: action.snackBarType || "default"
        }
    }

    if (action.type === hideSnackBarType) {
        return {
            ...state,
            snackBarMessage: ""
        }
    }

    return state;
};
