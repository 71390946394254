import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType } from './NavMenu';

const initialState = {
};


export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    showSnackBar: (message, type) => ({ type: showSnackBarType, message, snackBarType: type }),
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        default:
            return state;
    }
};
